import React from "react";
import PropTypes from "prop-types";

import ContentLayout from "Components/ContentLayout";
import Heading2 from "Components/styleguide/Heading2";
import PageDescription from "Components/PageDescription";
import OrganizationSecurity from "Components/OrganizationSecurity";

class OrganizationSecurityPage extends React.Component {
  render() {
    return (
      <ContentLayout id="settings-members" className="settings">
        <Heading2>Security</Heading2>
        <PageDescription>
          Two-factor authentication (TFA) provides additional security for your
          account. With TFA enabled, you log in to the site with a verification
          code in addition to your username and password.
        </PageDescription>
        <OrganizationSecurity {...this.props.params} />
      </ContentLayout>
    );
  }
}

OrganizationSecurityPage.propTypes = {
  router: PropTypes.shape({
    push: PropTypes.func.isRequired
  }),
  params: PropTypes.shape({
    organizationId: PropTypes.string
  }),
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired
  })
};
export default OrganizationSecurityPage;
