import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";

import SettingLine from "Components/SettingLine";
import Label from "Components/fields/Label";
import ButtonWrapper from "Components/ButtonWrapper";
import Button from "Components/Button";

const Layout = styled.div`
  a {
    min-width: 185px;
    text-align: right;
    color: ${props => props.theme.links};
    text-decoration: none;
  }
  .actions {
    padding-top: 32px;
    justify-content: flex-start;
    .outline {
      position: relative;
    }
    .disable {
      position: absolute;
    }
    button + button {
      margin-left: 16px;
    }
  }
`;

const InfoLayout = styled.div`
  display: flex;
  align-items: center;
  span:first-of-type {
    width: 50%;
  }
  span:nth-of-type(2) {
    width: 20%;
  }
`;

const FormWrapper = styled.div`
  padding: 24px 32px 32px;
  font-size: 14px;
  color: #38485e;
`;

const BrowsersList = styled.div`
  padding-top: 32px;
  .row {
    display: flex;
    align-items: flex-start;
    font-size: 14px;
    line-height: 26px;
    label {
      width: 118px;
    }
  }
`;

class OrganizationConnectedAccounts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      enabled: false,
      enabledDate: "",
      browsers: [
        {
          browser: "Chrome",
          expires: "Wed, 2019-01-02 18:20"
        }
      ],
      trustedBrowsers: [
        {
          browser: "Chrome",
          expires: "Wed, 2019-01-02 18:20"
        }
      ]
    };
    this.enableTFA = this.enableTFA.bind(this);
    this.disableTFA = this.disableTFA.bind(this);
  }

  enableTFA() {
    this.setState({
      enabled: true
    });
  }

  disableTFA() {
    this.setState({
      enabled: false
    });
  }

  // TODO: Add proper links to connect accounts.

  render() {
    return (
      <Layout>
        <SettingLine
          info={
            <InfoLayout className="account-info">
              <span>
                <strong>TFA Application</strong>
              </span>
            </InfoLayout>
          }
          noToggle={true}
          isOpen={true}
        >
          <FormWrapper>
            Generate verification codes from a mobile or desktop application.
            {this.state.enabled ? (
              <ButtonWrapper className="actions">
                <Button onClick={this.disableTFA}>Reset application</Button>
                <Button className="outline disable">Disable TFA</Button>
              </ButtonWrapper>
            ) : (
              <ButtonWrapper className="actions">
                <Button onClick={this.enableTFA}>Set up application</Button>
              </ButtonWrapper>
            )}
          </FormWrapper>
        </SettingLine>
        {this.state.enabled && (
          <React.Fragment>
            <SettingLine
              info={
                <InfoLayout className="account-info">
                  <span>
                    <strong>Trusted Browsers</strong>
                  </span>
                </InfoLayout>
              }
              noToggle={true}
              isOpen={true}
            >
              <FormWrapper>
                Browsers that will not require a verification code.
                <BrowsersList>
                  {this.state.trustedBrowsers.map((browser, index) => (
                    <div key={index} className="row">
                      <Label>{browser.browser}</Label>
                      <div className="info">Expires {browser.expires}</div>
                    </div>
                  ))}
                </BrowsersList>
                <ButtonWrapper className="actions">
                  <Button>Set trusted browsers</Button>
                </ButtonWrapper>
              </FormWrapper>
            </SettingLine>
            <SettingLine
              info={
                <InfoLayout className="account-info">
                  <span>
                    <strong>Recovery Codes</strong>
                  </span>
                </InfoLayout>
              }
              noToggle={true}
              isOpen={true}
            >
              <FormWrapper>
                Pre-generated, one-time-use codes intended as a fallback should
                other methods be unavailable.
                <ButtonWrapper className="actions">
                  <Button>Get new recovery codes</Button>
                  <Button className="outline">
                    View unused recovery codes
                  </Button>
                </ButtonWrapper>
              </FormWrapper>
            </SettingLine>
          </React.Fragment>
        )}
      </Layout>
    );
  }
}

OrganizationConnectedAccounts.propTypes = {
  title: PropTypes.string,
  closeForm: PropTypes.func,
  errors: PropTypes.object,
  onSave: PropTypes.func,
  intl: PropTypes.object
};

export default injectIntl(OrganizationConnectedAccounts);
<Layout>Connected accounts content goes here.</Layout>;
